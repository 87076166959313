export default {
  sources: {
    table: "OD3_Source",
  },
  units: {
    table: "OD3_BDE_Unit",
    field: "name",
    source: "source",
  },
  forms: {
    table: "OD3_BDE_Form",
  },
  pages: {
    table: "OD3_BDE_Page",
  },
  lists: {
    table: "OD3_BDE_List",
    children: "OD3_BDE_ListEntry",
  },
  result: {
    table: "OD3_BDE_Result",
  },
};
